@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap");
@import "./variables.module.scss";

.googleButton {
  width: 320px;
  height: 34px;
  background-color: #f7c548;
  margin: auto;
  font-family: "Lexend Deca", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  transition: 0.1s;
  &:hover {
    opacity: 0.85;
  }
}

.googleButtonExpert {
  width: 250px;
  height: 50px;
  background-color: #ff4242;
  color: white;
  margin: auto;

  font-family: "Lexend Deca", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  transition: 0.1s;
  &:hover {
    opacity: 0.85;
  }
}
.googleButtonNav {
  font-weight: 600;
  padding: 4px 10px;
  margin: 0 3px;
  transition: 0.2s;
  color: $black;
  cursor: pointer;
  transition: 0.1s;
  &:hover {
    opacity: 0.85;
  }
}

@media screen and (max-width: $mobile) {
  .googleButton {
    width: 250px;
  }
}

.googleButtonBig {
  width: 400px;
  height: 60px;
  background-color: #f7c548;
  margin: auto;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  transition: 0.1s;
  &:hover {
    opacity: 0.85;
  }
}

@media screen and (max-width: $mobile) {
  .googleButtonBig {
    width: 250px;
    height: 34px;
    background-color: #f7c548;
    margin: auto;
    font-family: "Lexend Deca", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 4px;
    user-select: none;
    cursor: pointer;
    transition: 0.1s;
    &:hover {
      opacity: 0.85;
    }
  }
}


.googleLogo {
  width: 14px;
  margin-right: 4px;
}

.googleLogoExpert {
  width: 14px;
  margin-right: 4px;
  fill: white;
}

.googleLogoBig {
  width: 22px;
  margin-right: 10px;
}

@media screen and (max-width: $mobile) {
  .googleLogoBig {
    width: 14px;
    margin-right: 4px;
  }
}
