.profileImage {
  border-radius: 100%;
  width: 40px;
  transition: 0.1s;
  margin-left: 10px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

@media screen and (max-width: 800px) {
  .bubbleContainer {
    display: none;
  }
}
