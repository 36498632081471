@import "../variables.module.scss";

.pageContainer {
  max-width: 950px;
  margin: auto;
  color: $black;
}

.contentContainer {
  margin: 80px 20px;
}

.preHeader {
  font-size: 22px;
  font-weight: 700;
  color: $red;
}

.splashText {
  font-size: 50px;
  font-weight: 700;
  color: $black;
}

.rightRow {
  margin-top: 40px;
  display: flex;
}

.alignRight {
  margin-left: auto;
  display: flex;
}

.pill {
  background: #d2d2d2;
  padding: 5px 10px;
  font-weight: 600;
  border-radius: 6px;
  margin-right: 4px;
  transition: 200ms;
  &:hover {
    cursor: pointer;
    background: #dadada;
  }
}

.selectedPill {
  background: $red;
  color: white;
  padding: 5px 10px;
  font-weight: 600;
  border-radius: 6px;
  margin-right: 4px;
  transition: 200ms;
}

.pricesContainer {
  display: flex;
  justify-content: space-around;
  max-width: 600px;
  margin: auto;
  margin-top: 40px;
}

.priceBlockFree {
  background: #fff;
  padding: 40px 20px;
  border-radius: 10px;
  width: 200px;
}

.priceBlockPro {
  background: #d0f0fd;
  padding: 40px 20px;
  border-radius: 10px;
  width: 200px;
}

.priceName {
  font-size: 30px;
  font-weight: 700;
  color: $black;
  text-align: center;
  margin-bottom: 15px;
}

.benefit {
  font-size: 14px;
  margin-bottom: 15px;
  display: flex;
}

.price {
  width: fit-content;
  margin: auto;
}

.amount {
  font-size: 40px;
  font-weight: 900;
  color: $black;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 35px;
}

.underAmount {
  text-align: center;
  font-size: 12px;
}

.check {
  margin-right: 8px;
  margin-top: 2px;
  color: #58bc82;
  font-size: 14px;
  min-width: 14px;
}

.upgradeButton {
  background: $black;
  color: #fafafa;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  margin: 15px auto 0 auto;
  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
  &:active {
    opacity: 1;
  }
}

.believerHeader {
  font-weight: 700;
  text-align: center;
  font-size: 40px;
  margin-top: 40px;
}

.believerDescription {
  margin: 9px auto 0 auto;
  text-align: center;
  font-size: 16px;
  max-width: 650px;
  line-height: 1.5em;
}

.believerContainer {
  background: #ffeab6;
  padding: 40px 20px;
  border-radius: 10px;
  width: 400px;
  margin: 20px auto;
}

@media screen and (max-width: $mobile) {
  .contentContainer {
    margin-top: 20px;
  }

  .splashText {
    font-size: 34px;
    font-weight: 700;
    color: $black;
  }

  .pricesContainer {
    display: block;
  }

  .priceBlockPro {
    width: 100%;
    box-sizing: border-box;
  }

  .priceBlockFree {
    width: 100%;
    box-sizing: border-box;
  }

  .believerContainer {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    box-sizing: border-box;
  }

  .believerHeader {
    font-size: 30px;
  }
}
