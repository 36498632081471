@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Open+Sans:wght@400;600&display=swap");

html {
  background: #faf9f9;
  box-sizing: border-box;
}

.pageHeader {
  margin: 100px auto 40px auto;
  max-width: 550px;
  font-family: "Merriweather", serif;
  font-size: 24px;
  font-weight: 600;
  color: #ff4242;
}

.formContainer {
  max-width: 450px;
  background-color: #ffffff;
  margin: 10px auto 0 auto;
  padding: 56px 48px;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 100, 0.15) 0px 4px 10px -2px,
    rgba(80, 80, 80, 0.2) 0px 3px 9px -3px;
}

.formHeading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
  color: #282c34;
}

.formSubheading {
  margin-bottom: 25px;
  color: #282c34;
  opacity: 0.6;
  font-weight: 600;
  font-size: 12px;
}

.inputLeft {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.niceInput {
  margin-left: 4px;
  height: 20px;
  border: 1px solid #a7a7a7;
  border-radius: 2px;
  font-weight: 600;
  font-family: inherit;
}

.invalidMessage {
  color: #dd3a3a;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}

.holdContinue {
  display: flex;
}

.continue {
  background: #ff4242;
  width: 100%;
  font-weight: 600;
  padding: 10px 0;
  text-align: center;
  color: white;
  border-radius: 5px;
  transition: 0.2s;
  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
  &:active {
    opacity: 1;
    background: #dd3a3a;
  }
}

.continueInactive {
  background: #ff4242;
  width: 100%;
  font-weight: 600;
  padding: 10px 0;
  text-align: center;
  color: white;
  border-radius: 5px;
  transition: 0.2s;
  opacity: 0.6;
}
