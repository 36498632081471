@import "../variables.module.scss";
$primary: #ff4242;

div.scrollingContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
div.scrollingContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
div.scrollingContainer::-webkit-scrollbar-track {
  background: #c9c9c9;
}

html {
  background: #faf9f9;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
}

.notProWarning{
  padding: 10px 15px;
  margin: 10px auto;
  border-radius: 5px;
  background: #f7c548;
  color: white;
  font-weight: 700;
  width: fit-content;
  text-decoration: none;
  transition: .1s;
  &:hover{
    opacity: .9;
  }
}

 a  {
  text-decoration: none;
}

.splitLine {
  background: $primary;
  height: 2px;
  margin: 0 40px;
}

.split {
  display: flex;
}

.userPagePreview {
  margin-top: 20px;
  margin-right: 20px;
  // background: #ffffff;
  width: 60%;
}

.previewTopRow {
  display: flex;
  margin-bottom: 15px;
}

.previewHeader {
  font-size: 24px;
  font-weight: 700;
  color: #282c34;
}

.previewSizeSelector {
  display: flex;
  justify-content: center;
  // margin: 15px;
  margin-left: auto;
}

.previewSizeSelectorButton {
  background: #d2d2d2;
  padding: 5px 10px;
  font-weight: 600;
  border-radius: 6px;
  margin-right: 4px;
  transition: 200ms;
  &:hover {
    cursor: pointer;
    background: #dadada;
  }
}

.previewSizeSelectorButtonSelected {
  background: $primary;
  color: white;
  padding: 5px 10px;
  font-weight: 600;
  border-radius: 6px;
  margin-right: 4px;
  transition: 200ms;
}

.userPageContainerdesktop {
  min-height: 400px;
  height: calc(100vh - 220px);
  overflow: auto;
  border-color: #000000;
  border-style: solid;
  border-width: 4px;
  border-radius: 18px;
  margin-left: 20px;
}

.userPageContainermobile {
  min-height: 400px;
  height: calc(100vh - 220px);
  width: fit-content;
  overflow: auto;
  border-color: #000000;
  border-style: solid;
  border-width: 4px;
  border-radius: 18px;
  margin: auto;
}

.conditionalSizedesktop {
  // max-width: 300px;
}

.desktopSizeInfo {
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
}

.conditionalSizemobile {
  width: 300px;
  max-width: 300px;
}

.pageHeader {
  margin: 100px auto 40px auto;
  max-width: 550px;
  font-family: "Merriweather", serif;
  font-size: 24px;
  font-weight: 600;
  color: $primary;
  font-weight: 600;
}

.pageContainer {
  margin: 0 25px 0 25px;
}

.scrollingContainer {
  width: 40%;
  overflow-y: -moz-scrollbars-vertical;
  overflow-y: scroll;
  overflow-x: unset;
  height: calc(100vh - 84px);
}

.formContainer {
  margin: 20px 40px 0 40px;
}

.tabsContainer {
  display: flex;
}

.tabName {
  font-size: 18px;
  margin-bottom: 15px;
  margin-right: 25px;
  color: #282c34;
  &:hover {
    cursor: pointer;
  }
}
.redLineVisible {
  height: 4px;
  background: $primary;
  width: 80%;
  margin-bottom: 10px;
}

.redLineHidden {
  display: none;
}

.rightFloat {
  display: flex;
}

.yourPageButton {
  margin-left: auto;
  background: $primary;
  width: fit-content;
  color: white;
  padding: 8px;
  font-weight: 600;
  border-radius: 5px;
  transition: 0.2s;
  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
  &:active {
    opacity: 1;
    background: #dd3a3a;
  }
}

.yourPageButton a {
  color: white;
  text-decoration: none;
}

.sectionHeader {
  font-size: 18px;
  font-weight: 600;
}

.profImageEntryWrapper {
  margin-top: 10px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 100, 0.15) 0px 4px 10px -2px,
    rgba(80, 80, 80, 0.2) 0px 3px 9px -3px;
  padding: 15px;
  border-radius: 10px;
  max-width: 300px;
}

.profImageEntryContainer {
  padding: 5px;
  // margin-bottom: 10px;
}

.profImageRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadButton {
  display: flex;
  align-items: center;
  background: $primary;
  color: white;
  padding: 8px 15px;
  font-weight: 600;
  border-radius: 5px;
  transition: 0.2s;
  width: fit-content;
  height: 20px;
  margin-top: 10px;
  &:hover {
    background: #dd3a3a;
    cursor: pointer;
  }
}

.profImageContainer {
  margin: auto;
  width: fit-content;
}

.profImagePreview {
  border-radius: 100%;
  max-width: 100px;
}

.helpText {
  opacity: 0.8;
  font-size: 12px;
  margin-bottom: 10px;
}

.badgesContainer {
  margin-top: 10px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 100, 0.15) 0px 4px 10px -2px,
    rgba(80, 80, 80, 0.2) 0px 3px 9px -3px;
  padding: 20px 20px;
  border-radius: 10px;
  max-width: 500px;
}

.platformContainer {
  display: flex;
  margin-bottom: 18px;
}

.platformContainer:nth-child(4) {
  margin-bottom: 0;
}

.socialLinkInputContainer {
  width: 100%;
}

.socialIconContainer {
  margin-right: 10px;
}

.linkSection {
  margin-top: 30px;
  margin-bottom: 30px;
}

.leftSide {
  width: 80%;
}

.rightSide {
  display: flex;
  flex-direction: column;
}

.poopHandle {
  width: fit-content;
  color: #282c34;
  transition: 200ms;
  align-self: flex-end;
  margin-bottom: 5px;

  &:hover {
    cursor: grab;
    opacity: 1;
  }
  &:active {
    color: $primary;
    opacity: 1;
    cursor: grabbing;
  }
}

.trashIcon {
  width: fit-content;
  color: #282c34;
  transition: 200ms;
  align-self: flex-end;
  margin-bottom: 5px;

  &:hover {
    cursor: pointer;
    color: #282c34d2;
  }
  &:active {
    color: $primary;
  }
}

.linkThumbnailPreview {
  width: 50px;
  margin-top: auto;
  border-radius: 5px;
}

.existingLinksContainer {
  margin-bottom: 40px;
}

.existingLink {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 100, 0.15) 0px 4px 10px -2px,
    rgba(80, 80, 80, 0.2) 0px 3px 9px -3px;
  padding: 15px;
  border-radius: 10px;
  max-width: 500px;
}

.upgradeContainer {
  box-sizing: border-box;
  font-weight: 600;
  width: 100%;
  background: #ffeab6;
  padding: 30px;
  margin-top: 15px;
  margin-bottom: 25px;
  border-radius: 10px;
}

.upgradeButton {
  background: $black;
  color: #fafafa;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  margin: 15px auto 0 auto;
  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
  &:active {
    opacity: 1;
  }
}

.spaceUnder {
  height: 140px;
}

.newLinkEntry {
  margin-top: 10px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 100, 0.15) 0px 4px 10px -2px,
    rgba(80, 80, 80, 0.2) 0px 3px 9px -3px;
  padding: 15px;
  border-radius: 10px;
  max-width: 500px;
}

.uploadImageNewLink {
  background: $primary;
  color: white;
  padding: 8px 12px;
  font-weight: 600;
  border-radius: 5px;
  transition: 0.2s;
  width: fit-content;
  &:hover {
    cursor: pointer;
    background: #ff5858;
  }
  &:active {
    background: #dd3a3a;
  }
}

.newLinkImagePreviewContainer {
  width: 100%;
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

.newLinkImagePreview {
  max-width: 150px;
  border-radius: 5px;
  text-align: center;
}

.linkInputContainer {
  padding: 5px 0;
}

.linkInput {
  display: block;
  width: 98%;
  font-family: inherit;
  color: rgb(0, 0, 0);
  border: none;
  outline: none;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  line-height: unset;
  transition: box-shadow 0.1s ease-out 0s;
  box-shadow: rgba(0, 58, 81, 0.3) 0px 1px;
  padding-bottom: 5px;
  transition: 100ms;
  &:focus {
    box-shadow: $primary 0px 2px;
  }
}

.simpleArea {
  width: 100%;
  margin-top: 5px;
  display: block;
  max-width: 500px;
  height: 55px;
  font-family: inherit;
  box-sizing: border-box;
  font-family: inherit;
  // color: rgb(0, 58, 81);
  padding: 5px;
  font-size: 14px;
  border-color: rgba(0, 58, 81, 0.3);
  border-radius: 5px;
  transition: 0.1s;
  &:focus {
    outline: none;
    border-color: rgba(0, 58, 81, 0.5);
  }
}

.deleteLink {
  margin-top: 15px;
  font-weight: 600;
  color: #dd3a3a;
  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
}

.holdSave {
  display: flex;
  flex-direction: row-reverse;
}

.linkSaveButton {
  background: $primary;
  color: white;
  padding: 8px 12px;
  font-weight: 600;
  border-radius: 5px;
  transition: 0.2s;
  width: fit-content;
  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
  &:active {
    opacity: 1;
    background: #dd3a3a;
  }
}

.linkSaveButtonDisabled {
  background: #ff8181;
  color: white;
  padding: 8px 12px;
  font-weight: 600;
  border-radius: 5px;
  width: fit-content;
}

.spacer {
  margin-bottom: 180px;
}

@media screen and (max-width: 1000px) {
  .userPagePreview {
    display: none;
  }

  .splitLine {
    display: none;
  }

  .formContainer {
    margin: 10px auto 0 auto;
  }

  .scrollingContainer {
    width: 100%;
    max-width: 600px;
    margin: auto;
    height: unset;
    overflow: unset;
  }
}

// THEMES
.allThemes {
  width: 100%;
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
}

.themeElement {
  margin-bottom: 30px;
  margin-right: 15px;
}

.themeSquare {
  box-sizing: border-box;
  height: 200px;
  width: 100%;
  background: $primary;
  margin-right: 10px;
  margin-left: 10px;
  border: #8f8f8f;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
}

.disabledHover {
  &:hover {
    cursor: unset;
  }
}

.gloomyTop {
  background-color: #7678ed;
  height: 50%;
}

.gloomyBottom {
  background-color: #53dd6c;
  height: 50%;
}

.campahTop {
  background-color: $primary;
  height: 50%;
}

.campahBottom {
  background-color: #fafafa;
  height: 50%;
}

.clefairyTop {
  background: linear-gradient(90deg, #ffafbd 0%, #ffc3a0 35%, #ffc3a0 100%);
  height: 50%;
}

.clefairyBottom {
  background: linear-gradient(90deg, #ffafbd 0%, #ffc3a0 35%, #ffc3a0 100%);
  height: 50%;
}

.mulberryTop {
  background: linear-gradient(90deg, #85ffbd 0%, #fffb7d 100%);
  height: 50%;
}

.mulberryBottom {
  background: linear-gradient(90deg, #85ffbd 0%, #fffb7d 100%);
  height: 50%;
}

.themeName {
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 5px;
  font-weight: 600;
}

.exampleThemeLink {
  text-align: center;
  margin-left: 8px;
  color: #7678ed;
  font-weight: 600;
}

.themeElement > a:link {
  text-decoration: none;
}
.themeElement > a:visited {
  text-decoration: none;
}

.customThemeHolder {
  margin-bottom: 40px;
  margin-top: 30px;
}

.useCustomThemeLabel {
  margin-right: 15px;
}

.customThemeRow {
  display: flex;
  margin-top: 10px;
}

.rail {
  height: 20px;
  width: 40px;
  border-radius: 10px;
  background: #53dd6c;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
  }
}

.railNo {
  height: 20px;
  width: 40px;
  border-radius: 10px;
  background: #555555;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
  }
}

.slider {
  position: relative;
  height: 14px;
  width: 14px;
  background: white;
  border-radius: 100%;
  top: 3px;
  left: 3px;
  transition: 0.1s;
  &:hover {
    cursor: pointer;
  }
}

.sliderSelected {
  position: relative;
  height: 14px;
  width: 14px;
  background: white;
  border-radius: 100%;
  top: 3px;
  left: 3px;
  transform: translateX(20px);
  transition: 0.1s;
  &:hover {
    cursor: pointer;
  }
}

.customThemeRowColor {
  display: flex;
  margin-top: 12px;
  align-items: center;
}

.colorLabel {
  width: 104px;
}

.colorInput {
  margin-left: 10px;
  display: block;
  width: 100px;
  font-family: inherit;
  color: rgb(0, 0, 0);
  border: none;
  outline: none;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  line-height: unset;
  transition: box-shadow 0.1s ease-out 0s;
  box-shadow: rgba(0, 58, 81, 0.3) 0px 1px;
  padding-bottom: 5px;
  transition: 100ms;
  &:focus {
    box-shadow: $primary 0px 2px;
  }
}

.previewColor {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  border-radius: 2px;
  border: #ced3da;
  border-width: 1px;
  border-style: solid;
}

.hideCampahSection {
  margin-bottom: 400px;
}

.upgrade {
  background-color: yellow;
  padding: 15px;
  margin-bottom: 30px;
}
