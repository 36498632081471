@import "../variables.module.scss";

.contentContainer {
  color: $black;
  margin: 0 50px;
  box-sizing: border-box;
}

.flexSplash {
  margin-bottom: 180px;
  text-align: center;
}

.lefty {
  margin-bottom: 40px;
  margin-top: 60px;
}

.splashHeader {
  font-size: 74px;
  line-height: 96%;
  font-weight: 700;
  margin: 0 auto 15px auto;
  max-width: 800px;
}

.splashDescription {
  margin-top: 20px;
  margin-bottom: 20px;
}

.imgContainer {
  width: 100%;
  max-width: 500px;
  margin: 0 auto 0 auto;
}

.splashImg {
  width: 100%;
  border-radius: 10px;
  margin: auto;
}
.imgContainerEnd {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 50px auto;
}

.splashImgEnd {
  width: 100%;
  border-radius: 10px;
  margin: auto;
}

.dashboardButton {
  background: $red;
  border-radius: 5px;
  color: white;
  width: fit-content;
  margin: auto;
  padding: 15px 25px;
  font-weight: 600;
  font-size: 18px;
  &:hover {
    cursor: pointer;
    opacity: 0.85;
  }
}

.demoWrapper {
  background: white;
  margin: auto;
}

.demoContainer {
  // background-color: #fcf8e8;

  display: flex;
  // flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  padding: 100px 50px;
  text-align: center;
  box-sizing: border-box;
  background: white;
  max-width: 1200px;
  margin: auto;
  padding-bottom: 120px;
}

.demoContainerReverse {
  // background-color: #fcf8e8;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  padding: 100px 50px;
  text-align: center;
  box-sizing: border-box;
  background: white;
  max-width: 1200px;
  margin: auto;
  padding-bottom: 120px;
}

.demoTextHold {
  width: 50%;
  margin-right: 20px;
  height: fit-content;
  color: $black;
}

.demoTextHoldRight {
  width: 50%;
  margin-left: 100px;
  height: fit-content;
  color: $black;
}

.demoHeader {
  font-size: 40px;
  font-weight: 600;
  text-align: left;
  color: $black;
  margin-bottom: 20px;
}

.demoSubHeader {
  font-size: 22px;
  text-align: left;
}

.demoImageContainer {
  width: 50%;
}

.demoImage {
  width: 100%;
  max-width: 800px;
  box-shadow: 0 9px 15px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

.divider {
  width: 100%;
  max-width: 1300px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 1px;
  margin: 90px auto;
}

.empowerSection {
  padding-top: 50px;
  padding: 50px 20px 70px 20px;
  text-align: center;
  color: $black;
}
.sectionHeader {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 15px;
}

.sectionSubHeader {
  font-size: 22px;
  margin: auto;
  margin-bottom: 50px;
  max-width: 800px;
}

.placeholder {
  background-color: bisque;
  height: 800px;
}

.buttonContainerBottom{
  margin-bottom: 80px;
}

@media screen and (max-width: 800px) {
  .splashHeader {
    font-size: 50px;
  }

  .flexSplash {
    margin-bottom: 100px;
  }

  .contentContainer {
    margin: 30px 20px 20px 20px;
  }

  .demoHeader {
    font-size: 28px;
    margin: 0 0 10px 0;
  }

  .demoSubHeader {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .demoContainer {
    display: block;
    margin: auto;
  }

  .demoContainerReverse {
    display: block;
    margin: auto;
  }

  .demoTextHold {
    width: 100%;
  }

  .demoTextHoldRight {
    margin: 0;
    width: 100%;
  }

  .demoImageContainer {
    width: 100%;
  }

  .sectionHeader {
    font-size: 30px;
  }

  .sectionSubHeader {
    font-size: 18px;
  }
}
