@import "../variables.module.scss";

.pageHeader {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 15px;
}

.pageContainer {
  max-width: 800px;
  margin: auto;
  color: $black;
}

.contentContainer {
  margin: 20px;
}

.accountCard {
  display: flex;
  align-items: center;
}

.accountImageContainer {
  margin-right: 20px;
}

.accountImage {
  border-radius: 100%;
}

.headerLabel {
  color: #8c8c8c;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}

.viaHold {
  display: flex;
  margin-top: 9px;
}

.headerLabelRight {
  color: #fafafa;
  background-color: #8c8c8c;
  padding: 5px 9px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-left: auto;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.dashboardButton {
  background: $red;
  width: fit-content;
  color: white;
  padding: 8px 12px;
  font-weight: 600;
  border-radius: 5px;
  transition: 0.2s;
  margin-bottom: 30px;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
  &:active {
    opacity: 1;
    background: #dd3a3a;
  }
}

.contentContainer > a:link {
  text-decoration: none;
}

.subHeader {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 600;
  color: #8c8c8c;
  text-transform: uppercase;
}

.accountType {
  margin-top: 5px;
  font-size: 22px;
  font-weight: 600;
}

.upgradeContainer {
  margin-top: 20px;
  background: #00bbf92c;
  padding: 15px;
  border-radius: 10px;
  max-width: 300px;
}

.upgradeHeader {
  font-weight: 600;
  margin-bottom: 10px;
}

.upgradeItem {
  font-size: 14px;
  margin: 5px 0;
}

.upgradeButton {
  background: $black;
  color: #fafafa;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  margin-top: 15px;
  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
  &:active {
    opacity: 1;
  }
}
