@import "../variables.module.scss";
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Open+Sans:wght@400;600&display=swap");

.rightSide > a:link {
  text-decoration: none;
  color: $black;
}

.rightSide > a:visited {
  text-decoration: none;
  color: $black;
}

.navContentContainer {
  max-width: 1300px;
  margin: auto;
  display: flex;
}

.logoContainer {
  height: 18px;
  padding-top: 1px;
}

.logoImage {
  height: 100%;
}

.home {
  display: flex;
  font-size: 30px;
  line-height: 96%;
  letter-spacing: -0.02em;
  font-weight: 700;
  padding: 30px;
  font-family: "Merriweather", serif;
  font-size: 24px;
  font-weight: 600;
  color: $black;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    text-shadow: 4px 2px 15px rgba($color: #fff, $alpha: 0.25);
  }
}

.campah {
  margin-left: 5px;
}

.rightSide {
  display: flex;
  margin-left: auto;
  align-items: center;
  color: $black;
  margin-right: 30px;
}

.navItem {
  font-weight: 600;
  padding: 4px 10px;
  margin: 0 3px;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.navItemPRO {
  background-color: #f7c548;
  color: white;
  border-radius: 8px;
  font-weight: 700;
  padding: 4px 10px;
  margin: 0 3px;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.yellowStrip {
  // background-color: #ff4242;
}

.mobileNav {
  display: none;
}

.menuIcon {
  font-size: 28px;
}

.drawer {
  position: fixed;
  top: 75px;
  width: 100vw;
  height: 100vh;
  background: white;
}

.drawerItem {
  padding: 20px 10px;
  font-weight: 600;
}

.spacer {
  height: 1px;
  width: 100%;
  background: rgb(218, 218, 218);
}

.drawerContentContainer {
  padding: 30px;
  font-size: 20px;
}

.drawerContentContainer > a:link {
  text-decoration: none;
  color: inherit;
}
.drawerContentContainer > a:visited {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 800px) {
  .navItem {
    display: none;
  }
  .navItemPRO {
    display: none;
  }
  .mobileNav {
    display: block;
  }
}
