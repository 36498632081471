@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import "../variables.module.scss";
$primary: #7678ed;
$secondary: #53dd6c;
$tertiary: #fafafa;
$bio-color: #0d1f22;
$text-color: #fafafa;

.all {
  background: $secondary;
}

.cardContainer > a:link {
  color: $black;
  text-decoration: none;
}

.cardContainer > a:visited {
  color: $black;
  text-decoration: none;
}

.cardContainer > a:active {
  color: $black;
  text-decoration: none;
}

.container {
  font-family: "Poppins", sans-serif;
  min-height: calc(100vh - 300px);
  background-color: $primary;
  padding: 20px;
}

.above {
  height: 198px;
}

.circle {
  width: 200px;
  height: 200px;
  background: white;
  position: relative;
  top: 100px;
  left: calc(50vw - 100px);
  border-radius: 100%;
}

.circleSplit {
  width: 200px;
  height: 200px;
  background: white;
  position: relative;
  top: 100px;
  left: calc(25vw - 100px);
  border-radius: 100%;
}

.imageCircle {
  height: fit-content;
  width: 180px;
  height: 180px;
  position: relative;
  top: 10px;
  left: 10px;
  border-radius: 100%;
  object-fit: cover;
}

.prac {
  height: 200px;
  background-color: $primary;
  border-radius: 100px 100px 0px 0px;
  border-top-left-radius: 400px 100px;
  border-top-right-radius: 400px 100px;
  display: flex;
  // align-items: flex-end;
  justify-content: center;
}

.socialSection {
  position: relative;
  top: 115px;
}

.socialIcon {
  font-size: 32px;
  transition: 0.1s;
  margin: 0 12px 12px 12px;
  color: $tertiary;
  &:hover {
    color: $secondary;
    cursor: pointer;
    transform: scale(1.05);
  }
}

.card {
  // width: 50%;
  // border: 1px solid #c4c3c3;
  padding: 30px;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 35px;
  display: flex;
  transform: scale(1);
  transition: 0.1s;
  background: $tertiary;

  &:hover {
    background-color: $secondary;
    color: white;
    cursor: pointer;
    transform: scale(1.05);
  }
}

.cardIcon {
  height: 120px;
  width: 120px;
  min-width: 120px;
  margin-right: 20px;
  border-radius: 5px;
}

.cardIconNoDesc {
  height: 80px;
  width: 80px;
  min-width: 80px;
}

.cardIconImage {
  border-radius: 5px;

  width: 100%;
}

.cardIconImageNoDesc {
  border-radius: 5px;
  width: 100%;
}

.cardTitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
  text-decoration: none;
}

.cardDescription {
  font-size: 14px;
}

.cardTitleNoDesc {
  font-size: 28px;
  font-weight: 600;
  margin: auto;
  text-align: center;
}

.contentContainer {
  max-width: 800px;
  margin: auto;
}

.splashHeader {
  width: fit-content;
  font-size: 34px;
  line-height: 96%;
  font-weight: 700;
  margin-bottom: 20px;
  color: $tertiary;
}

.bioInfo {
  font-size: 20px;
  margin-bottom: 40px;
  color: $tertiary;
}

.footer a {
  text-decoration: none;
  background: $primary;
  padding: 0 0 80px 0;
  display: flex;
  justify-content: center;
  font-size: 30px;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-family: "Merriweather", serif;
  font-size: 24px;
  font-weight: 600;
  color: black;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    text-shadow: 4px 2px 15px rgba($color: #fff, $alpha: 0.25);
  }
}

.unclaimedContainer {
  margin: 40px;
}

.unclaimedContentContainer {
  max-width: 500px;
  margin: auto;
  margin-top: 200px;
}

.logo {
  font-size: 16px;
  text-decoration: none;
  letter-spacing: -0.02em;
  font-weight: 700;
  font-family: "Merriweather", serif;
  font-weight: 600;
  color: $red;
  transition: 0.3s;
  margin-bottom: 15px;
  &:hover {
    cursor: pointer;
  }
}

.unclaimedDescription {
  text-decoration: none;
  padding: 0 0 25px 0;
  display: flex;
  justify-content: center;
  letter-spacing: -0.02em;
  font-family: "Merriweather", serif;
  font-size: 22px;
  font-weight: 600;
  color: black;
  transition: 0.3s;
}

.claimButton {
  background-color: $red;
  color: white;
  width: fit-content;
  padding: 10px 25px;
  border-radius: 20px;
  letter-spacing: -0.02em;
  font-family: "Merriweather", serif;
  font-size: 15px;
  font-weight: 600;
  margin: auto;
  transition: 0.1s;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

.unclaimedWarning {
  text-decoration: none;
  padding: 30px 0 25px 0;
  display: flex;
  justify-content: center;
  letter-spacing: -0.02em;
  font-family: "Merriweather", serif;
  font-size: 12px;
  font-weight: 400;
  color: #666;
}

@media screen and (max-width: 800px) {
  .container {
    padding: 20px;
    min-height: calc(100vh - 200px);
  }

  .circle {
    width: 170px;
    height: 170px;
    top: 100px;
    left: calc(50vw - 85px);
  }

  .imageCircle {
    width: 160px;
    height: 160px;
    top: 5px;
    left: 5px;
  }

  .bioInfo {
    font-size: 16px;
    margin-bottom: 25px;
  }

  .above {
    height: 130px;
  }

  .circle {
    top: 40px;
  }

  .prac {
    height: 120px;
  }

  .socialSection {
    top: 85px;
  }

  .socialIcon {
    transform: scale(0.8);
    margin: 0 5px 5px 5px;
  }

  .splashHeader {
    font-size: 28px;
  }

  .card {
    padding: 20px;
    margin-right: 0;
    margin-bottom: 15px;
    border-radius: 4px;
  }

  .cardTitleNoDesc {
    font-size: 22px;
    text-align: center;
  }

  .cardIconNoDesc {
    height: 50px;
    width: 50px;
    min-width: 50px;
    margin-right: 10px;
  }

  .cardIcon {
    border-radius: 3px;
  }

  .cardTitle {
    font-size: 16px;
  }

  .cardIcon {
    height: 80px;
    width: 80px;
    min-width: 80px;
    margin-right: 20px;
  }
}

// DESKTOP PREVIEW

.containerdesktop {
  font-family: "Poppins", sans-serif;
  min-height: calc(50vh);
  background-color: $primary;
  padding: 20px 20px 10px 20px;
}

.alldesktop {
  background: $secondary;
}

.abovedesktop {
  height: 150px;
}

.circledesktop {
  width: 150px;
  height: 150px;
  background: white;
  position: relative;
  top: 75px;
  left: calc(50% - 74px);
  border-radius: 100%;
}

.imageCircledesktop {
  height: fit-content;
  width: 135px;
  height: 135px;
  position: relative;
  top: 7px;
  left: 7px;
  border-radius: 100%;
  object-fit: cover;
}

.pracdesktop {
  height: 125px;
  background-color: $primary;
  border-radius: 75px 75px 0px 0px;
  border-top-left-radius: 300px 75px;
  border-top-right-radius: 300px 75px;
  display: flex;
  justify-content: center;
}

.pracEmptydesktop {
  height: 100px;
  background-color: $primary;
  border-radius: 75px 75px 0px 0px;
  border-top-left-radius: 300px 75px;
  border-top-right-radius: 300px 75px;
  display: flex;
  justify-content: center;
}

.socialSectiondesktop {
  position: relative;
  top: 86px;
}

.socialIcondesktop {
  font-size: 25px;
  transition: 0.1s;
  margin: 0 9px 9px 9px;
  color: $tertiary;
  &:hover {
    color: $secondary;
    cursor: pointer;
    transform: scale(1.05);
  }
}

.carddesktop {
  padding: 22px;
  border-radius: 7px;
  margin-bottom: 27px;
  display: flex;
  transform: scale(1);
  transition: 0.1s;
  background: $tertiary;

  &:hover {
    background-color: $secondary;
    color: white;
    cursor: pointer;
    transform: scale(1.05);
  }
}

.cardIcondesktop {
  height: 90px;
  width: 90px;
  min-width: 90px;
  margin-right: 15px;
  border-radius: 5px;
}

.cardIconNoDescdesktop {
  height: 60px;
  width: 60px;
  min-width: 60px;
}

.cardIconImagedesktop {
  border-radius: 5px;
  width: 100%;
}

.cardIconImageNoDescdesktop {
  border-radius: 7px;
  width: 100%;
}

.cardTitledesktop {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  text-decoration: none;
}

.cardDescriptiondesktop {
  font-size: 14px;
}

.cardTitleNoDescdesktop {
  font-size: 25px;
  font-weight: 600;
  margin: auto;
  text-align: center;
}

.contentContainerdesktop {
  max-width: 600px;
  margin: auto;
}

.splashHeaderdesktop {
  width: fit-content;
  font-size: 25px;
  line-height: 96%;
  font-weight: 700;
  margin-bottom: 15px;
  color: $tertiary;
}

.bioInfodesktop {
  font-size: 15px;
  margin-bottom: 30px;
  color: $tertiary;
}

.footerdesktop a {
  text-decoration: none;
  background: $primary;
  padding: 0 0 60px 0;
  display: flex;
  justify-content: center;
  letter-spacing: -0.02em;
  font-family: "Merriweather", serif;
  font-size: 18px;
  font-weight: 600;
  color: black;
  transition: 0.3s;
  min-height: 25vh;
  &:hover {
    cursor: pointer;
    text-shadow: 6px 3px 24px rgba($color: #fff, $alpha: 0.25);
  }
}

// Mobile PREVIEW

.containermobile {
  font-family: "Poppins", sans-serif;
  background-color: $primary;
  padding: 20px;
  min-height: calc(50vh);
}

.allmobile {
  background: $secondary;
}

.abovemobile {
  height: 110px;
}

.circlemobile {
  width: 150px;
  height: 150px;
  background: white;
  position: relative;
  top: 75px;
  left: calc(50% - 74px);
  border-radius: 100%;

  top: 100px;
  top: 40px;
}

.imageCirclemobile {
  height: fit-content;
  width: 140px;
  height: 140px;
  position: relative;
  border-radius: 100%;
  object-fit: cover;

  top: 5px;
  left: 5px;
}

.pracmobile {
  background-color: $primary;
  border-radius: 75px 75px 0px 0px;
  border-top-left-radius: 300px 75px;
  border-top-right-radius: 300px 75px;
  display: flex;
  justify-content: center;
  height: 120px;
}

.pracEmptymobile {
  background-color: $primary;
  border-radius: 75px 75px 0px 0px;
  border-top-left-radius: 300px 75px;
  border-top-right-radius: 300px 75px;
  display: flex;
  justify-content: center;
  height: 100px;
}

.socialSectionmobile {
  position: relative;
  top: 85px;
}

.socialIconmobile {
  font-size: 28px;
  transition: 0.1s;
  color: $tertiary;
  transform: scale(0.8);
  margin: 0 6px 6px 6px;
  &:hover {
    color: $secondary;
    cursor: pointer;
    transform: scale(1.05);
  }
}

.cardmobile {
  padding: 20px;
  border-radius: 7px;
  margin-bottom: 27px;
  display: flex;
  transform: scale(1);
  transition: 0.1s;
  background: $tertiary;

  &:hover {
    background-color: $secondary;
    color: white;
    cursor: pointer;
    transform: scale(1.05);
  }
}

.cardIconmobile {
  height: 70px;
  width: 70px;
  min-width: 70px;
  margin-right: 15px;
  border-radius: 3px;
}

.cardIconNoDescmobile {
  height: 50px;
  width: 50px;
  min-width: 50px;
  margin-right: 10px;
}

.cardIconImagemobile {
  border-radius: 5px;
  width: 100%;
}

.cardIconImageNoDescmobile {
  border-radius: 7px;
  width: 100%;
}

.cardTitlemobile {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  text-decoration: none;
}

.cardDescriptionmobile {
  font-size: 12px;
}

.cardTitleNoDescmobile {
  font-size: 18px;
  font-weight: 600;
  margin: auto;
  text-align: center;
}

.contentContainermobile {
  max-width: 600px;
  margin: auto;
}

.splashHeadermobile {
  width: fit-content;
  font-size: 25px;
  line-height: 96%;
  font-weight: 700;
  margin-bottom: 15px;
  color: $tertiary;
  font-size: 22px;
}

.bioInfomobile {
  margin-bottom: 30px;
  color: $tertiary;

  font-size: 13px;
  margin-bottom: 25px;
}

.footermobile a {
  text-decoration: none;
  background: $primary;
  padding: 0 0 60px 0;
  display: flex;
  justify-content: center;
  letter-spacing: -0.02em;
  font-family: "Merriweather", serif;
  font-size: 18px;
  font-weight: 600;
  color: black;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    text-shadow: 6px 3px 24px rgba($color: #fff, $alpha: 0.25);
  }
}
